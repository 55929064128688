import request from "@/utils/request.js";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.cloudgame;
export default {
  // 获取用户信息
  userInfo(params) {
    return request.get(baseApi + "/users/info/v2", { params });
  },
  nickname(data) {
    return request.put(baseApi + "/users/nicknames", data);
  },
  resetAvatar(data) {
    return request.post(baseApi + "/users/reset-avatar", data);
  },
  // 修改用户信息
  editUserInfo(data) {
    return request.put(baseApi + "/users/userInfo", data);
  },
};
