import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 移动端适配（Rem 布局适配，设置rem基准值）
import "lib-flexible/flexible";

// 1. 引入你需要的组件
import {
  Button,
  Field,
  CellGroup,
  NavBar,
  Cell,
  Form,
  Dialog,
  Toast,
  ImagePreview,
  Uploader,
  DropdownMenu,
  DropdownItem,
  DatetimePicker,
  Popup,
  Area,
  List,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Switch,
  Sticky,
  ShareSheet,
  ActionSheet,
  Swipe,
  SwipeItem,
} from "vant";
// 2. 引入组件样式
import "vant/lib/index.css";
// 3. 注册你需要的组件
createApp(App)
  .use(store)
  .use(router)
  .use(Button)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(NavBar)
  .use(Form)
  .use(Dialog)
  .use(Toast)
  .use(ImagePreview)
  .use(Uploader)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(DatetimePicker)
  .use(Popup)
  .use(Area)
  .use(List)
  .use(RadioGroup)
  .use(Radio)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Switch)
  .use(Sticky)
  .use(ShareSheet)
  .use(ActionSheet)
  .use(Swipe)
  .use(SwipeItem)
  .mount("#app");
// 导入全局样式
import "./assets/style/variable.less";
import "./assets/style/vant-ui-reset.css";
