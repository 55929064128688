<template>
  <router-view />
  <QuPopup></QuPopup>
</template>
<script>
import QuPopup from "./components/QuPopup.vue";
export default {
  components: {
    QuPopup,
  },
};
</script>
<style>
/* #app {
  padding-bottom: 15px;
} */
html {
  background: #f1f1f1;
  font-family: PingFang SC;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
}
@font-face {
  font-family: DINPro;
  src: url("./assets/font/DINPRO-MEDIUM.OTF");
}
</style>
