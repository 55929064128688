import request from "@/utils/request.js";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.anycloudAdmin;
export default {
  btnClick(data) {
    return request.post(baseApi + "/click/save", data);
  },
  hasRedDot(data) {
    return request.post(baseApi + "/click/showAlert", data);
  },
  showOnce(data) {
    return request.post(baseApi + "/click/showAlertOnlyOnce", data);
  },
  // 问题反馈提交
  problemSave(data) {
    return request.post(baseApi + "/problem/save", data);
  },
  // 问题反馈文件上传
  problemFile(data) {
    return request.post(baseApi + "/problem/file", data);
  },
  // 相关建议提交
  suggestionSave(data) {
    return request.post(baseApi + "/suggestion/save", data);
  },
  alertPay(data) {
    return request.post(baseApi + "/click/alertPay", data);
  },
  // 查询所有推送中的活动
  activityList(params) {
    return request.get(baseApi + "/activity/showActivityList", { params });
  },
  // 活动点击量统计
  clickCount(params) {
    return request.get(baseApi + "/activity/clickCount", { params });
  },
};
