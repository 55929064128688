import request from "@/utils/request";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.cloudgame;
export default {
  // 获取正在运行的云桌面列表，带扣费
  dListMore() {
    return request.get(baseApi + "/desktops/list");
  },
  // 关机一个云桌面
  shutDown(data) {
    return request.post(baseApi + "/desktops/shutdownNew/v2", data);
  },
  // 关机所有云桌面
  shutDownAll() {
    return request.get(baseApi + "/desktops");
  },
  // 云桌面控制台（包含云游戏和云办公）
  desktops() {
    return request.get(baseApi + "/official/adapter/desktops");
  },
  // 关机云办公的云桌面
  shutDownOffice(params) {
    return request.get(baseApi + "/gpc/desktops/close", { params });
  },
  // 关机云天的机器
  unlockSky(data) {
    return request.post(baseApi + "/iCloudSky/unlock", data);
  },
};
