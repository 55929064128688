import request from "@/utils/request";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.cloudgame;
export default {
  // 获取用户点券生命周期
  limitedCoinRecode(params) {
    return request.get(baseApi + "/account/limitedCoinRecode", { params });
  },
  // 获取用户充值记录
  rechargeRecord(params) {
    return request.get(baseApi + "/users/rechargeRecode", { params });
  },
  // 获取用户消费记录
  userGameRecode(params) {
    return request.get(baseApi + "/desktops/userGameRecode", { params });
  },

  // 充值页面金额面额和权益卡数据
  productInfo(params) {
    return request.get(baseApi + "/orders/recharge/productInfo", { params });
  },
};
