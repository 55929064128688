import request from "@/utils/request.js";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.cloudgame;
export default {
  // 验证码
  smsCode(data) {
    return request.post(baseApi + "/sms/send_verification_code", data);
  },
  // 注册
  register(data) {
    return request.post(baseApi + "/users/register", data);
  },
  // 忘记密码
  passwords(data) {
    return request.post(baseApi + "/users/amend/passwords", data);
  },
};
