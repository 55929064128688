import { createStore } from "vuex";
import { LOGIN, USER, RECHARGE } from "@/api";
import { getUrlParm } from "@/utils/lib";
// 使用vuex-persistedstate插件来进行持久化
import createPersistedstate from "vuex-persistedstate";

const state = {
  tokenRelative: {
    token: "",
    refreshToken: "",
    expiresIn: "",
    loginTime: "",
  },
  userInfo: {},
  speed: "",
  downloadArr: [],
  delayedArr: [],
  finished1: true,
  finished2: true,
  openid: "",
  showPopup: false,
  question: {},
};
const mutations = {
  setTokenRelative(state, data) {
    if (Object.keys(data).length === 0) {
      state.tokenRelative = data;
      return;
    }
    const obj = {
      token: data.accessToken,
      refreshToken: data.refreshToken,
      expiresIn: data.expiresIn,
      loginTime: data.loginTime === "" ? data.loginTime : new Date().getTime(),
    };
    state.tokenRelative = obj;
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  },
  setDownloadArr(state, data) {
    state.downloadArr = data;
  },
  setDelayedArr(state, data) {
    state.delayedArr = data;
  },
  SET_SPEED(state, data) {
    state.speed = data;
  },
  setFinished1(state, data) {
    state.finished1 = data;
  },
  setFinished2(state, data) {
    state.finished2 = data;
  },
  SET_OPENID(state, openid) {
    state.openid = openid;
  },
  setShowPopup(state, data) {
    state.showPopup = data;
  },
  setQuestion(state, data) {
    state.question = data;
  },
};
const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LOGIN.oauth(payload).then((res) => {
        // 将token和下一次刷新的token存入vuex
        if (res.data.data) {
          commit("setTokenRelative", res.data.data);
          resolve();
        } else {
          reject(res.data.errorMessage);
        }
      });
    });
  },
  loginByRrefreshToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      LOGIN.oauth(payload).then((res) => {
        // 将token和下一次刷新的token存入vuex
        if (res.data.data) {
          commit("setTokenRelative", res.data.data);
          // 获取用户信息
          USER.userInfo().then((res) => {
            if (res.data.errorCode === "OK") {
              commit("setUserInfo", res.data.data);
              resolve();
            }
          });
        } else {
          reject(res.data.errorMessage);
        }
      });
    });
  },
  // 获取用户信息以及openid
  getUserInfoAndOpenid({ commit, state }) {
    return new Promise((resolve, reject) => {
      USER.userInfo().then((res) => {
        if (res.data.errorCode === "OK") {
          commit("setUserInfo", res.data.data);
          const urlParm = getUrlParm();
          if (urlParm && urlParm.code && !state.openid) {
            RECHARGE.getToken({ code: urlParm.code }).then((res) => {
              if (res.data.errorCode === "OK") {
                if (res.data.data.openid) {
                  commit("SET_OPENID", res.data.data.openid);
                }
                resolve();
              } else {
                reject();
              }
            });
          } else {
            resolve(); // 这里返回如果没有缓存的话获取不到openid
          }
        } else {
          reject();
        }
      });
    });
  },
};
export default createStore({
  state,
  getters: {},
  mutations,
  actions,
  // 配置插件
  plugins: [createPersistedstate({ storage: window.localStorage })],
});
