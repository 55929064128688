import store from "@/store";
import { createRouter, createWebHashHistory } from "vue-router";
import { OMS } from "@/api/index.js";
import { cardQuestion, hourQuestion } from "@/utils/question.js";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login/index.vue"),
    meta: {
      title: "登录",
    },
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/Register/index.vue"),
  //   meta: {
  //     title: "注册",
  //   },
  // },
  // {
  //   path: "/register_mini",
  //   name: "register_mini",
  //   component: () => import("../views/Register/mini.vue"),
  //   meta: {
  //     title: "注册",
  //   },
  // },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/Forget/index.vue"),
    meta: {
      title: "重置密码",
    },
  },
  {
    path: "/forget_mini",
    name: "forget_mini",
    component: () => import("../views/Forget/mini.vue"),
    meta: {
      title: "重置密码",
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings/index.vue"),
    meta: {
      title: "设置",
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home/index.vue"),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About/index.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../views/Agreement/index.vue"),
    meta: {
      title: "责任声明",
    },
  },
  {
    path: "/nickname",
    name: "nickname",
    component: () => import("../views/EditNickname/index.vue"),
    meta: {
      title: "修改昵称",
    },
  },
  {
    path: "/editpassword",
    name: "editpassword",
    component: () => import("../views/EditPassword/index.vue"),
    meta: {
      title: "修改密码",
    },
  },
  {
    path: "/issue",
    name: "issue",
    component: () => import("../views/Issue/index.vue"),
    meta: {
      // title: "联系客服",
      title: "在线客服",
    },
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("../views/Introduction/index.vue"),
    meta: {
      title: "使用指南",
    },
  },
  {
    path: "/limited",
    name: "limited",
    component: () => import("../views/LimitedBonus/index.vue"),
    meta: {
      title: "点券",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/FAQ/index.vue"),
    meta: {
      title: "常见问题",
    },
  },
  {
    path: "/recharge",
    name: "recharge",
    component: () => import("../views/Recharge/recharge.vue"),
    meta: {
      title: "充值",
    },
  },
  {
    path: "/rechargeRecord",
    name: "rechargeRecord",
    component: () => import("../views/RechargeRecord/index.vue"),
    meta: {
      title: "充值记录",
    },
  },

  {
    path: "/expenseRecord",
    name: "expenseRecord",
    component: () => import("../views/ExpenseRecord/index.vue"),
    meta: {
      title: "消费记录",
    },
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("../views/Survey/index.vue"),
    meta: {
      title: "调查问卷",
    },
  },
  {
    path: "/surveyForm",
    name: "surveyForm",
    component: () => import("../views/Survey/surveyForm.vue"),
    meta: {
      title: "调查问卷",
    },
  },
  {
    path: "/task",
    name: "task",
    component: () => import("../views/Task/index.vue"),
    meta: {
      title: "任务中心",
    },
  },
  {
    path: "/share",
    name: "share",
    component: () => import("@/components/ShareView.vue"),
    meta: {
      title: "只有屏幕也能畅玩3A大作",
    },
  },
  {
    path: "/networkTest",
    name: "networkTest",
    component: () => import("../views/NetworkTest/index.vue"),
    meta: {
      title: "网络测速",
    },
  },
  {
    path: "/shutDown",
    name: "shutDown",
    component: () => import("../views/ShutDown/index.vue"),
    meta: {
      title: "云桌面控制台",
    },
  },
  {
    path: "/addServicer",
    name: "addServicer",
    component: () => import("../views/Register/addServicer.vue"),
    meta: {
      title: "获取激活码",
    },
  },
  {
    path: "/byHours",
    name: "byHours",
    component: () => import("../views/Recharge/components/byHours.vue"),
    meta: {
      title: "包时福利",
    },
  },
  {
    path: "/problemFeedback",
    name: "problemFeedback",
    component: () => import("../views/ProblemFeedback/index.vue"),
    meta: {
      title: "反馈与建议",
    },
  },
  {
    path: "/co",
    name: "cooperate",
    component: () => import("../views/Cooperate/index.vue"),
    meta: {
      title: "商务合作",
    },
  },
  {
    path: "/activityList",
    name: "activityList",
    component: () => import("../views/ActivityList/index.vue"),
    meta: {
      title: "活动",
    },
  },
  {
    path: "/handleOperation_mini",
    name: "handleOperation",
    component: () => import("../views/HandleOperation/index.vue"),
    meta: {
      title: "手柄版操作指南",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// 在白名单内的路由不需要token也能跳转过去
const whiteList = [
  "/about",
  "/login",
  "/forget",
  "/forget_mini",
  "/agreement",
  "/register",
  "/register_mini",
  "/faq", // 有页面路由守卫
  "/share",
  "/task",
  "/recharge", // 有页面路由守卫
  "/byHours", // 有页面路由守卫
  "/introduction",
  "/issue",
  "/networkTest",
  "/addServicer",
  "/co",
  "/problemFeedback", // 有页面路由守卫
  "/handleOperation_mini",
];
const paths = routes.map((item) => item.path);
router.beforeEach((to) => {
  if (paths.indexOf(to.path) === -1) {
    if (!store.state.tokenRelative.token) {
      return "/login";
    } else {
      return "/home";
    }
  }
  if (whiteList.indexOf(to.path) < 0 && !store.state.tokenRelative.token) {
    return "/login";
  }
  if (
    store.state.tokenRelative.token &&
    (to.path === "/login" || to.path === "/register" || to.path === "/forget")
  ) {
    return "/home";
  }
  // if (to.path === "/register") {
  //   // 注册页面返回到登录页面用的go(-1),需要判断跳转到注册页面的前一个页面是否是登录页面，如果不是则不允许跳转
  //   if (from.path !== "/login") {
  //     return false;
  //   }
  // }
  document.title = to.meta.title;
  // 页面滚动到最顶部
  window.scrollTo(0, 0);
});
router.afterEach((to, from) => {
  store.commit("setShowPopup", false);
  let fromPath = from.path;
  // 在这几个页面不弹窗
  let toPaths = [
    "/recharge",
    "/byHours",
    "/login",
    "/register",
    "/forget",
    "/register_mini",
    "/forget_mini",
    "/agreement",
  ];
  // console.log(sessionStorage.getItem("category"));
  // console.log(fromPath, to.path);
  if (
    (fromPath === "/recharge" || fromPath === "/byHours" || fromPath === "/") &&
    !toPaths.includes(to.path) &&
    sessionStorage.getItem("category")
  ) {
    OMS.alertPay({
      mobile: store.state.userInfo.mobile,
      type:
        sessionStorage.getItem("category") === "取消支付月套餐问卷"
          ? "alert_package"
          : "alert_duration",
    }).then((res) => {
      if (res.data.errorCode === "OK") {
        if (res.data.data == "1") {
          if (sessionStorage.getItem("category") === "取消支付月套餐问卷") {
            store.commit("setQuestion", cardQuestion);
          }
          if (sessionStorage.getItem("category") === "取消支付包时卡问卷") {
            store.commit("setQuestion", hourQuestion);
          }
          store.commit("setShowPopup", true);
        }
      }
    });
  }
  if (to.path === "/home") {
    /**
     * 获取当前平台：是否iOS
     */
    let isIos = function () {
      var u = navigator.userAgent;
      if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1) {
        return true;
      }
      return false;
    };
    /**
     * ios 解决页面返回不刷新问题
     */
    let refreshPageByBack = function () {
      if (isIos()) {
        // 在后退的界面上添加 判断是否是返回
        window.addEventListener("pageshow", function (e) {
          if (
            e.persisted ||
            (window.performance && window.performance.navigation.type == 2)
          ) {
            window.location.reload();
          }
        });
      }
    };
    refreshPageByBack();
  }
});
export default router;
