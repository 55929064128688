import axios from "axios";
import Qs from "qs";
import store from "../store";
import { Dialog } from "vant";
import router from "@/router";
import { LOGIN } from "@/api";
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 5000,
});

// 序列化
// service.defaults.paramsSerializer = (params) => {
//   let str = "";

//   Object.keys(params).forEach((key) => {
//     if (key !== "m") {
//       // console.log(params[key])
//       str += `&${key}=${params[key]}`;
//     }
//   });
//   str = str.slice(1);
//   return str;
// };

// 存储当前的请求标识
const pendingRequest = new Map();

// 生成标识key
function generateReqKey(config) {
  const { method, url, params, data } = config;
  return [method, url, Qs.stringify(params), Qs.stringify(data)].join("&");
}
// 添加请求 防止多次请求
function addPending(config) {
  const requestKey = generateReqKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });
}

// 移除请求
function removePending(config) {
  const requestKey = generateReqKey(config);
  if (pendingRequest.has(requestKey)) {
    const cancelToken = pendingRequest.get(requestKey);
    cancelToken(requestKey);
    pendingRequest.delete(requestKey);
  }
}

// 刷新令牌
// function refreshToken() {

//   return service
//     .post("/users/oauth2", {
//       grantType: "refresh",
//       refreshToken: store.state.tokenRelative.refreshToken,
//     })
//     .then((res) => res.data);
// }
// 防止多次刷新
let isRefreshing = false;
// 缓存的过期请求
let retryRequest = [];

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    removePending(config);
    addPending(config);
    // 登录不拦截
    if (config.url.indexOf("/users/oauth2") >= 0) {
      config.headers["Client-Id"] = +parseInt(Math.random() * 100000);
      return config;
    }
    // 获取验证码不拦截
    if (config.url.indexOf("/sms/send_verification_code") >= 0) {
      return config;
    }
    // 注册不拦截
    if (config.url.indexOf("/users/register") >= 0) {
      return config;
    }
    // 重置密码不拦截
    if (config.url.indexOf("/users/amend/passwords") >= 0) {
      return config;
    }
    // 支付不拦截
    if (config.url.indexOf("/orders/recharge/public") >= 0) {
      return config;
    }
    // 获取openId不拦截
    if (config.url.indexOf("/users/wx/authorization") >= 0) {
      return config;
    }
    // 测速请求接口不拦截
    if (config.url.indexOf("/config/h5RegionList") >= 0) {
      return config;
    }
    // 酒店版本接口
    if (config.url.indexOf("/hotel") >= 0) {
      return config;
    }
    // 刷新token
    if (store.state.tokenRelative.loginTime) {
      const loginTime = store.state.tokenRelative.loginTime;
      const passed = (new Date().getTime() - loginTime) / 1000;
      const expiresIn = store.state.tokenRelative.expiresIn - 100;
      // console.log(passed)
      if (passed >= expiresIn) {
        if (!isRefreshing) {
          isRefreshing = true;
          LOGIN.oauth({
            grantType: "refresh",
            refreshToken: store.state.tokenRelative.refreshToken,
          })
            .then(({ data }) => {
              // console.log("刷新token");
              // console.log(data.data, "res.data");
              store.commit("setTokenRelative", data.data);
              return data.data.accessToken;
            })
            .then((newToken) => {
              // console.log('刷新token成功，执行过期的请求')
              retryRequest.forEach((cb) => cb(newToken));
              retryRequest = [];
            })
            .catch((err) => {
              console.log("refresh token error: ", err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
        return new Promise((resolve) => {
          retryRequest.push((newToken) => {
            // 因为config中的token是旧的，所以刷新token后要将新token传进来
            config.headers.Authorization = "Bearer " + newToken;
            resolve(config);
          });
        });
      }
    }

    const token = store.state.tokenRelative.token;
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (err) => {
    console.log(err);
    // 对请求错误做些什么
    return Promise.reject(err);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config);
    if (response.data.errorCode !== "OK") {
      if (response.data.errorMessage) {
        Dialog.alert({
          message: response.data.errorMessage,
        });
      }
    }
    return response;
  },
  (err) => {
    // 对响应错误做点什么
    if (err.response) {
      switch (err.response.status) {
        case 401:
          // 令牌无效（token无效）
          // Dialog.alert({
          //   message: err.response.data.errorMessage,
          // }).then(() => {
          store.commit("setTokenRelative", {});
          // store.commit("setUserInfo", {});
          router.replace("/login");
          location.reload();
          // });
          break;
        case 404:
          Dialog.alert({
            message: err.response.data.errorMessage,
          });
          break;
        case 403:
          Dialog.alert({
            message: err.response.data.errorMessage,
          });
          break;
        case 500:
          Dialog.alert({
            message: "服务器错误，请联系管理员",
          });
          break;
        default:
          Dialog.alert({
            message: err.response.data.errorMessage,
          });
      }
    }
    return Promise.reject(err);
  }
);

export default service;
