<template>
  <van-popup v-model:show="isShow" round position="bottom" @close="closePopup">
    <div class="q-content">
      <div class="question">{{ question.question }}</div>
      <van-checkbox-group v-model="checkAns" checked-color="#4691ff">
        <van-checkbox
          shape="square"
          :name="item.id"
          v-for="item in question.answers"
          @click="changeCheck(item.id)"
          :key="item.label"
          >{{ item.label }}</van-checkbox
        >
      </van-checkbox-group>
      <van-field
        v-model="otherAnswer"
        v-show="checkAns.includes(0)"
        placeholder="请输入原因，至少6个字符"
        maxlength="25"
      />
      <van-button block type="primary" @click="onSubmit">确定</van-button>
    </div>
  </van-popup>
</template>

<script>
import { TASK } from "@/api";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      isShow: false,
      otherAnswer: "",
      checkAns: [],
    };
  },
  computed: {
    ...mapState(["showPopup", "question"]),
  },
  watch: {
    showPopup: {
      handler(n) {
        this.isShow = n;
        this.checkAns = [];
        this.otherAnswer = "";
        if (n === true) {
          this.category = sessionStorage.getItem("category");
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(["setShowPopup"]),
    changeCheck(val) {
      if (val == 0) {
        this.checkAns = [0];
      } else {
        if (this.checkAns.includes(0)) {
          this.checkAns.splice(this.checkAns.indexOf(0), 1);
        }
      }
    },
    onSubmit() {
      if (this.checkAns.length === 0) {
        this.$toast("请勾选后再提交");
        return;
      }
      let otherAnswer = this.otherAnswer.replace(/\s/g, "");
      // console.log(obj);
      let answers = [];
      if (this.checkAns.includes(0)) {
        // 勾选的其他，必填其他原因，最少6个字符
        if (otherAnswer.length < 6) {
          this.$toast("原因请至少输入6个字符");
          this.otherAnswer = otherAnswer;
          return;
        }
        answers = [otherAnswer];
      } else {
        answers = [...this.checkAns];
      }
      TASK.questionnaireSaveV2({
        category: this.category,
        answers,
      }).then((res) => {
        if (res.data.errorCode === "OK") {
          sessionStorage.removeItem("category");
          this.$toast.success("感谢您的反馈");
          this.setShowPopup(false);
        }
      });
    },
    closePopup() {
      this.setShowPopup(false);
      sessionStorage.removeItem("category");
    },
  },
};
</script>

<style lang="css" scoped>
/* 问卷填写其他原因时输入框背景色 以下为问卷样式*/
.q-content {
  padding: 18px;
}
.q-content .question {
  font-size: 15px;
  color: #333;
  margin-bottom: 18px;
}
.van-field {
  height: 38px;
  background: #f3f3f3;
  border-radius: 3px;
  margin-bottom: 15px;
}
.van-checkbox {
  margin-bottom: 15px;
}
:deep(.van-checkbox__label) {
  color: #666;
}
:deep(.van-checkbox__icon) {
  border: 1px solid #1c3231;
}
</style>
