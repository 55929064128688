export const gameQuestions = [
  {
    question: "1. 为什么有些游戏鼠标移动起来很“飘”？",
    answer:
      "部分游戏存在鼠标移动感觉“飘”的情况，可以尝试在控制面板或游戏设置里调低指针移动速度/鼠标灵敏度。使用合适的鼠标垫也可以优化此种情况",
  },
  {
    question: "2. 为什么部分游戏需要收费？",
    answer: "我们提供的游戏均为正版游戏，部分游戏需要付费是由游戏平台决定的",
  },
  {
    question: "3. 租号注意事项",
    answer:
      "目前云游戏还不能完全支持租号软件，租号程序可能会出现部分游戏文件丢失，导致无法打开游戏的等异常情况，不建议您租赁账号，如出现游戏无法打开等问题，解决方法：直接选择关机结算，重新开启一台",
  },
  {
    question: "4. 输入法注意事项",
    answer:
      "系统输入法默认快捷键是“Shift”，如果跟您的游戏热键有冲突，可以在桌面右下角找到输入法图标右键进入设置进行更改快捷键",
  },
  {
    question: "5. 腾讯游戏账号异常问题",
    answer:
      "我们提供六个不同地区的服务器，后续会陆续添加更多服务器，请勿在不同地区之间频繁切换登录，会导致腾讯游戏账号异常，检测异地登录等问题，如果出现登录不上的情况，建议用手机扫码进行登录",
  },
  {
    question: "6.关于游戏存档说明",
    answer:
      "部分游戏因文件冲突或者存档内容过大等原因，无法进行存档。如需了解可存档单机游戏请联系（摩哈云专属客服莎莎）提供",
  },
];
export const chargeQuestions = [
  {
    question: "1. 如何充值费用？",
    answer:
      "用户可以在登录摩哈云账号后，在配置界面左侧点击“充值”按钮来进行账户充值；或者在进入大屏后，点击悬浮图标，在下拉框中也有充值渠道；还可以关注“摩哈云”公众号，在公众号内进行充值操作",
  },
  {
    question: "2. 使用时是如何收费的？",
    answer:
      "1、摩哈币：使用12分钟及12分钟以内按12分钟计费（开机时长不足30秒不计费）；2、包时套餐：按分钟实时计费",
  },
];
export const deviceQuestions = [
  {
    question: "1. 刚连上桌面的时候为什么会出现短时间卡顿？",
    answer:
      "再启动云端服务器时，有大量数据传输到本地终端，可能造成网络传输拥堵，所以会导致屏幕短时间内的卡顿和延迟",
  },
  {
    question: "2. 设备可以连接无线键鼠或者蓝牙键鼠吗？",
    answer: "设备不支持蓝牙键鼠，但是支持使用无线键鼠",
  },
  // {
  //   question: "3. 设备可以连接WI-FI吗？",
  //   answer: "云电脑主机可以通过WI-FI的方式连接网络，并且支持Wi-Fi 6",
  // },
  {
    question: "3. 显示器不支持HDMI接口怎么办？",
    answer:
      "可以购买相对应的转接口来进行连接，比如您的显示器是VGA接口的，可以购买一个HDMI转VGA的转接器",
  },
  {
    question: "4.音量过小原因有背景音乐无人声？",
    answer:
      "点击 Windows云桌面右下角喇叭图标进行调节。如果您的耳机为美标接口，可尝试将耳机接口稍微向外抽出一点。如无法解决建议您使用国标接口的耳机（请在接入电源线之前插入耳机，否则声音会从视频口HDMI输出，出现无声的状态）",
  },
  {
    question: "5.电源参数是多少？",
    answer:
      "建议您使用5V/2A或以上的电源适配器。需要注意的是，低功率适配器或劣质数据线/三合一充电线可能会导致连接失败",
  },
  {
    question: "6.是否支持麦克风语音输入（游戏开麦）功能？",
    answer: "暂不支持该功能，敬请期待",
  },
  {
    question: "7.手柄型号/什么手柄可以用？",
    answer: "当前已支持xbox同协议的手柄",
  },
];

export const otherQuestions = [
  {
    question: "1. 关机操作",
    answer:
      "云电脑并不能像家用电脑一样进行关机或者重启的操作，请勿在云桌面内进行关机或者重启，需要关机，请您按照正常的关机流程：点击悬浮窗口----点击关机----确认关机",
  },
  {
    question: "2. 分辨率的设置",
    answer:
      "每次进入大屏时，系统会默认选择标清，可以点击悬浮窗找到“修改分辨率”进行更改，如果您的网络状况稳定，建议选择高清，游戏体验更佳",
  },
  {
    question: "3. 关机后使用过的桌面还可以恢复吗？",
    answer: "桌面关机结算后，相应的桌面数据将被清空，请注意备份保存数据再关机",
  },
  {
    question: "4. 如何保存桌面数据",
    answer: "可以将数据上传到网盘或者第三方软件中保存",
  },
  {
    question: "5. 可以开多个桌面吗？",
    answer:
      "可以，还可以不同的桌面进行切换使用，桌面内点击悬浮窗口，找到正在运行的云桌面进行切换使用",
  },
  {
    question: "6. 能否在不同的设备上登录同一个摩哈云账号？",
    answer:
      "可以，摩哈云账号支持多端登录，但是不可以多端同时进入同一个桌面，一个云桌面只能支持一方进行操作，同时请您注意保护自己的账号隐私",
  },
];

export const cardQuestion = {
  question: "您未购买月套餐的原因是？多选",
  answers: [
    {
      label:
        "不知道有赠送30小时免费游戏时间和专属月套餐小时单价特权（标配2.5/小时，顶配4/小时）",
      id: 1,
    },
    {
      label: "购买3/6小时的包时段福利套餐已经够我每天的游戏时间了",
      id: 2,
    },
    {
      label: "不如单独按小时付费划算",
      id: 3,
    },
    {
      label: "暂时不想购买",
      id: 4,
    },
    {
      label: "其他",
      id: 0,
    },
  ],
};
export const hourQuestion = {
  question: "您未购买包时福利的原因是？多选",
  answers: [
    {
      label: "已经购买月套餐，且每日免费2小时游戏时间能够满足我日常游戏时间",
      id: 1,
    },
    {
      label: "生效使用时段和我日常使用时间不匹配",
      id: 2,
    },
    {
      label: "不知道购买后，到包时生效时间会自动优先使用",
      id: 3,
    },
    {
      label: "暂时不想购买",
      id: 4,
    },
    {
      label: "其他",
      id: 0,
    },
  ],
};
