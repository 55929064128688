import request from "@/utils/request";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.cloudgame;
export default {
  // 检查问卷是否填写过
  questionnaireCheck(params) {
    return request.get(baseApi + "/questionnaire/check", { params });
  },
  // 保存调查问卷
  questionnaireSave(data) {
    return request.post(baseApi + "/questionnaire/save", data);
  },
  // 调研问卷
  questionnaireSaveV2(data) {
    return request.post(baseApi + "/questionnaire/saveV2", data);
  },
  // 权益用户签到
  sign(params) {
    return request.get(baseApi + "/account/signIn", { params });
  },
  // 权益用户签到记录
  signInRecord(params) {
    return request.get(baseApi + "/account/signInRecord", { params });
  },
  // 获取任务完成状态
  missionRecords(params) {
    return request.get(baseApi + "/missions/missionRecodes/v2", { params });
  },
  // 微信分享前获取签名
  wxShare(params) {
    return request.get(baseApi + "/missions/wxShare", { params });
  },
  // 用户当前是否关注公众号
  officialFocus(params) {
    return request.get(baseApi + "/missions/officialFocus", { params });
  },
  // 用户分享成功
  officialShare(params) {
    return request.get(baseApi + "/missions/officialShare", { params });
  },
  // 完成某个任务
  completeTask(params) {
    return request.get(baseApi + "/missions/compliedAMission", { params });
  },
  // 获取某个任务完成情况,不传参即查“一次性充值不低于19元”的任务是否完成
  taskStatus(params) {
    return request.get(baseApi + "/missions/missionCompleted", { params });
  },
};
