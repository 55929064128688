import request from "@/utils/request.js";
import basePreApi from "./basePreApi";
const baseApi = basePreApi.cloudgame;
export default {
  // 充值
  rechargeWx(data) {
    return request.post(baseApi + "/orders/recharge/public", data);
  },
  getToken(params) {
    return request.get(baseApi + "/users/wx/authorization", { params });
  },
  // 组合支付抵扣详情
  paymentDetails(params) {
    return request.get(baseApi + "/orders/recharge/paymentDetails", { params });
  },
};
